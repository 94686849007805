$(document).ready(function() {
  
    new WOW().init();
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function() {
      window.history.pushState(null, "", window.location.href);
    };  
});
$('body').on('click', '.link-a1', function() {
  $(this).closest('.grid-a1').find('.panel-a1').addClass('opac');
  $(this).closest('.grid-a1').find('.panel-a2').addClass('in');
  $(this).closest('.grid-a1').find('.overlay-panel').fadeIn();

});

$('body').on('click', '.overlay-panel', function() {
  $(this).closest('.grid-a1').find('.panel-a1').removeClass('opac');
  $(this).closest('.grid-a1').find('.panel-a2').removeClass('in');
  $(this).closest('.grid-a1').find(this).fadeOut();
});
$('.next:last').addClass('last').hide();
$('.previous:first').addClass('first').hide();

$('.next').on('click', function() {

  $('.grid-a1').find('.panel-a1').removeClass('opac');
  $('.grid-a1').find('.panel-a2').removeClass('in');
  $('.overlay-panel').fadeOut();
  if ($(this).parents().closest('.grid-a1').nextAll().length != 0) {
    var showingElem = $(this).parents().closest('.grid-a1').next('.grid-a1');
    showingElem.find('.panel-a1').addClass('opac');

    showingElem.find('.panel-a2').addClass('in');
    showingElem.find('.overlay-panel').fadeIn();
    if (showingElem.find('.next').hasClass('last'))
      $(showingElem.find('.next').hide());
    else
      $(showingElem.find('.next').show());
  }

});

$('.previous').on('click', function() {

  $('.grid-a1').find('.panel-a1').removeClass('opac');
  $('.grid-a1').find('.panel-a2').removeClass('in');
  $('.overlay-panel').fadeOut();
  if ($(this).parents().closest('.grid-a1').prevAll().length != 0) {
    var showingElem = $(this).parents().closest('.grid-a1').prev('.grid-a1');
    showingElem.find('.panel-a1').addClass('opac');
    showingElem.find('.panel-a2').addClass('in');
    showingElem.find('.overlay-panel').fadeIn();
    if (showingElem.find('.previous').hasClass('first'))
      $(showingElem.find('.previous').hide());
    else
      $(showingElem.find('.previous').show());
  }
});

// $('body').on('click', '.reqtrig', function() {
//   $('.request-quote-popup').addClass('triggered');
//   $('body').addClass('noscroll');
  
// });

// $('body').on('click', '.req-close', function() {
//   $('.request-quote-popup').removeClass('triggered');
//   $('body').removeClass('noscroll');
// });


// $('body').on('click', '.carousel-indicators li', function() {
//     var activeIndex = $('.carousel-indicators li.active').removeClass('active').index();
//     var thisIndex = $(this).addClass('active').index();

//     if (activeIndex < thisIndex) {
//       console.log("after active element");
//       $('.triyadh').addClass('fadeInLeftDiff');
//       $('.triyadh').css('animation-name','fadeInLeftDiff');
//       $('.triyadh').removeClass('fadeInRightDiff'); 
//     } else if (activeIndex > thisIndex) { 
//       console.log("before active element"); 
//       $('.triyadh').removeClass('fadeInLeftDiff');
//       $('.triyadh').addClass('fadeInRightDiff');
//       $('.triyadh').css('animation-name','fadeInRightDiff');
//     } else {
//       console.log('same element');
//     }
//   });
